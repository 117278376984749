:root {
  --container-spacing: 2rem;
}

html {
  /* Prevent automatic iOS text adjustment */
  -webkit-text-size-adjust: 100%;
}

* {
  box-sizing: border-box;
}

body {
  display: flex;
  margin: 0;
  justify-content: center;
  font-family: sans-serif;
  background-color: black;
}

input,
button {
  font-size: 1rem;
}

.text-wrap {
  overflow-wrap: anywhere;
}

.hint {
  opacity: 0.5;
}

#root {
  flex: 1;
  max-width: 40rem;
  margin: 1rem;
}

header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
}

header > h1 {
  font-size: 1.5rem;
  color: white;
}

header > h1 > span {
  vertical-align: super;
  font-size: 0.5em;
}

.help-button {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  padding: 0;
  border: none;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
}

.help-button:hover {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
}

.container {
  padding: var(--container-spacing);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.65rem;
  background-color: rgb(30, 30, 30);
  color: white;
}

.container--calculator {
  display: flex;
  gap: var(--container-spacing);
  position: relative;
}

.container--record-list {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  gap: 1rem;
}

.output {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.output-item {
  font-size: 1.25rem;
  margin: 0;
  font-weight: bold;
  text-align: center;
}

.output-item--large {
  font-size: 3rem;
}

.save-button {
  font-size: 0.85rem;
  padding: 0.75rem 2rem;
  border: none;
  border-radius: 2rem;
  position: absolute;
  /* Calc removes remaining 25% of `gap` between columns */
  left: calc(75% - calc(var(--container-spacing) * 0.25));
  bottom: 0;
  font-weight: bold;
  line-height: 1rem;
  background-color: white;
  color: black;
  cursor: pointer;
  /* 1px accounts for 50% of parent container's border */
  transform: translate(-50%, calc(50% + 1px));
  z-index: 1;
}

.save-button:disabled {
  background-color: rgb(170, 170, 170);
  cursor: initial;
  cursor: not-allowed;
}

.save-button:not(:disabled):hover {
  background-color: gainsboro;
}

/* 
  Consider using a pre-processor to use centrally defined breakpoints in
  all CSS files.
*/
@media screen and (max-width: 420px) {
  .container--calculator {
    flex-direction: column;
  }

  .output {
    margin-top: calc(var(--container-spacing) / 2);
    margin-bottom: var(--container-spacing);
  }

  .save-button {
    left: 50%;
  }
}
