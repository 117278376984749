.record {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.record:first-of-type {
  padding-top: 0;
}

.record__data {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.record__desc {
  overflow-wrap: anywhere;
  line-height: 150%;
}

.record__delete-button {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;
  padding: 0.5rem;
  border: none;
  border-radius: 50%;
  background: none;
  color: white;
  cursor: pointer;
  line-height: 100%;
}

.record__delete-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
