.dialog {
  max-width: 500px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.65rem;
  z-index: 1;
  background-color: rgb(30, 30, 30);
  color: white;
}

.dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}
