.help-dialog__para {
  line-height: 150%;
}

.author-link {
  font-weight: bold;
  text-decoration: none;
  color: white;
}

.author-link:hover {
  text-decoration: underline;
}
