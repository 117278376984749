.main-form {
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  display: flex;
}

.main-form__label {
  color: #ffffffa6;
  font-size: .85rem;
  line-height: 200%;
}

.main-form__input {
  color: #fff;
  background-color: #000;
  border: 2px solid #fff3;
  border-radius: .65rem;
  width: 100%;
  padding: .5rem;
}

.main-form__input--error {
  border: 2px solid #ee4646;
}

.dialog {
  z-index: 1;
  color: #fff;
  background-color: #1e1e1e;
  border: 2px solid #fff3;
  border-radius: .65rem;
  max-width: 500px;
}

.dialog::backdrop {
  background-color: #000000bf;
}

.dialog-title {
  overflow-wrap: break-word;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.dialog-actions {
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 1rem;
  display: flex;
}

.dialog-actions__button {
  color: #fff;
  cursor: pointer;
  background-color: #646464;
  border: none;
  border-radius: .5rem;
  flex: 1;
  padding: .5rem 1rem;
}

.dialog-actions__button--confirm {
  background-color: #236fd9b3;
}

.help-dialog__para {
  line-height: 150%;
}

.author-link {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.author-link:hover {
  text-decoration: underline;
}

.description-dialog__input {
  color: inherit;
  background-color: #000;
  border: 2px solid #fff3;
  border-radius: .65rem;
  width: 100%;
  margin: 1rem 0;
  padding: .33rem .5rem;
}

.record {
  border-bottom: 1px solid #fff3;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 0;
  display: flex;
}

.record:first-of-type {
  padding-top: 0;
}

.record__data {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
}

.record__desc {
  overflow-wrap: anywhere;
  line-height: 150%;
}

.record__delete-button {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  color: #fff;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  padding: .5rem;
  line-height: 100%;
}

.record__delete-button:hover {
  background-color: #ffffff1a;
}

.record-list {
  margin: 0;
  padding: 0;
}

:root {
  --container-spacing: 2rem;
}

html {
  -webkit-text-size-adjust: 100%;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #000;
  justify-content: center;
  margin: 0;
  font-family: sans-serif;
  display: flex;
}

input, button {
  font-size: 1rem;
}

.text-wrap {
  overflow-wrap: anywhere;
}

.hint {
  opacity: .5;
}

#root {
  flex: 1;
  max-width: 40rem;
  margin: 1rem;
}

header {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  display: flex;
}

header > h1 {
  color: #fff;
  font-size: 1.5rem;
}

header > h1 > span {
  vertical-align: super;
  font-size: .5em;
}

.help-button {
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  text-align: center;
  color: #fff;
  cursor: pointer;
  background-color: #fff3;
  border: none;
  border-radius: 50%;
  flex-shrink: 0;
  padding: 0;
}

.help-button:hover {
  color: #000;
  background-color: #ffffffb3;
}

.container {
  padding: var(--container-spacing);
  color: #fff;
  background-color: #1e1e1e;
  border: 2px solid #fff3;
  border-radius: .65rem;
}

.container--calculator {
  gap: var(--container-spacing);
  display: flex;
  position: relative;
}

.container--record-list {
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;
  display: flex;
}

.output {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  gap: 1rem;
  display: flex;
}

.output-item {
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.output-item--large {
  font-size: 3rem;
}

.save-button {
  left: calc(75% - calc(var(--container-spacing) * .25));
  color: #000;
  cursor: pointer;
  z-index: 1;
  background-color: #fff;
  border: none;
  border-radius: 2rem;
  padding: .75rem 2rem;
  font-size: .85rem;
  font-weight: bold;
  line-height: 1rem;
  position: absolute;
  bottom: 0;
  transform: translate(-50%, calc(50% + 1px));
}

.save-button:disabled {
  cursor: initial;
  cursor: not-allowed;
  background-color: #aaa;
}

.save-button:not(:disabled):hover {
  background-color: #dcdcdc;
}

@media screen and (width <= 420px) {
  .container--calculator {
    flex-direction: column;
  }

  .output {
    margin-top: calc(var(--container-spacing) / 2);
    margin-bottom: var(--container-spacing);
  }

  .save-button {
    left: 50%;
  }
}

/*# sourceMappingURL=index.4716023b.css.map */
