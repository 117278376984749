.main-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.main-form__label {
  font-size: 0.85rem;
  line-height: 200%;
  color: rgba(255, 255, 255, 0.65);
}

.main-form__input {
  width: 100%;
  padding: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 0.65rem;
  background-color: black;
  color: white;
}

.main-form__input--error {
  border: 2px solid rgb(238, 70, 70);
}
